import { _ } from "vue-underscore";
export default {
	data: () => ({
		airline: {
			id: 0,
			name: "",
			checked_in_links: "",
			code: "",
			checked_in_links: "",
			baggage_url: "",
			phone_number: "",
			ratings: "1",
		},
    loading: false,
		error: "",
		backUrl: "/airlines"
	}),
	methods: {
		resetForm() {
			this.$refs.observer.reset();
			this.airline = {
				id: 0,
				name: "",
			};
		},
		validateAirline() {
			this.airline.id > 0 ? this.update() : this.add();
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
		},
		update() {
			let _vm = this;
			let fd = new FormData();
			if (_vm.airline.rectangularImage) {
				fd.append("rectangularImage", _vm.airline.rectangularImage);
			}
			if (_vm.airline.rectangularWhiteImage) {
				fd.append("rectangularWhiteImage", _vm.airline.rectangularWhiteImage);
			}
			if (_vm.airline.squareImage) {
				fd.append("squareImage", _vm.airline.squareImage);
			}
			if (_vm.airline.tailImage) {
				fd.append("tailImage", _vm.airline.tailImage);
			}
			Object.keys(_vm.airline).forEach(key => {
				fd.append(key, _vm.airline[key]);
			});
			fd.append("_method", "PATCH");
			this.axios
				.post("/airline/" + this.airline.id, fd)
				.then(function (response) {
					_vm.airline = response.data.data.data;
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},
		add() {
			let _vm = this;
			let fd = new FormData();
			// Image Stroe
			if (_vm.airline.rectangularImage) {
				fd.append("rectangularImage", _vm.airline.rectangularImage);
			}
			if (_vm.airline.rectangularWhiteImage) {
				fd.append("rectangularWhiteImage", _vm.airline.rectangularWhiteImage);
			}
			if (_vm.airline.squareImage) {
				fd.append("squareImage", _vm.airline.squareImage);
			}
			if (_vm.airline.tailImage) {
				fd.append("tailImage", _vm.airline.tailImage);
			}
			Object.keys(_vm.airline).forEach(key => {
				fd.append(key, _vm.airline[key]);
			});
			this.axios
				.post("/airline/", fd)
				.then(function (response) {
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},
		getDetail() {
			let _vm = this;
			this.axios
				.get("airline/" + this.$route.params.id)
				.then(function (response) {
					_vm.airline = response.data.data;
					if (
						_vm.airline.full_rectangular_image_url &&
						_vm.airline.full_rectangular_image_url.length > 0
					) {
						$("#imagePreview").css(
							"background-image",
							"url(" +
							_vm.airline.full_rectangular_image_url +
							"?date=" +
							Date.now() +
							")"
						);
						$("#imagePreview").hide();
						$("#imagePreview").fadeIn(650);
					}
					if (
						_vm.airline.full_rectangular_white_image_url &&
						_vm.airline.full_rectangular_white_image_url.length > 0
					) {
						$("#imagePreview1").css(
							"background-image",
							"url(" +
							_vm.airline.full_rectangular_white_image_url +
							"?date=" +
							Date.now() +
							")"
						);
						$("#imagePreview1").hide();
						$("#imagePreview1").fadeIn(650);
					}
					if (
						_vm.airline.full_square_image_url &&
						_vm.airline.full_square_image_url.length > 0
					) {
						$("#imagePreview2").css(
							"background-image",
							"url(" +
							_vm.airline.full_square_image_url +
							"?date=" +
							Date.now() +
							")"
						);
						$("#imagePreview2").hide();
						$("#imagePreview2").fadeIn(650);
					}
					if (
						_vm.airline.full_tail_image_url &&
						_vm.airline.full_tail_image_url.length > 0
					) {
						$("#imagePreview3").css(
							"background-image",
							"url(" +
							_vm.airline.full_tail_image_url +
							"?date=" +
							Date.now() +
							")"
						);
						$("#imagePreview3").hide();
						$("#imagePreview3").fadeIn(650);
					}
				})
				.catch(function () { });
		},
		getQueryString() {
			let queryString = "?search=";
			return queryString;
		},
		selectImageRectangularImage(event) {
			let _vm = this;
			var input = event.target;
			if (input.files && input.files[0]) {
				_vm.airline.rectangularImage = input.files[0];
				var reader = new FileReader();
				reader.onload = function (e) {
					$("#imagePreview").css(
						"background-image",
						"url(" + e.target.result + ")"
					);
					$("#imagePreview").hide();
					$("#imagePreview").fadeIn(650);
				};

				reader.readAsDataURL(input.files[0]);
			}
		},
		selectImageRectangularWhiteImage(event) {
			let _vm = this;
			var input = event.target;
			if (input.files && input.files[0]) {
				_vm.airline.rectangularWhiteImage = input.files[0];
				var reader = new FileReader();
				reader.onload = function (e) {
					$("#imagePreview1").css(
						"background-image",
						"url(" + e.target.result + ")"
					);
					$("#imagePreview1").hide();
					$("#imagePreview1").fadeIn(650);
				};

				reader.readAsDataURL(input.files[0]);
			}
		},
		selectImageSquareImage(event) {
			let _vm = this;
			var input = event.target;
			if (input.files && input.files[0]) {
				_vm.airline.squareImage = input.files[0];
				var reader = new FileReader();
				reader.onload = function (e) {
					$("#imagePreview2").css(
						"background-image",
						"url(" + e.target.result + ")"
					);
					$("#imagePreview2").hide();
					$("#imagePreview2").fadeIn(650);
				};

				reader.readAsDataURL(input.files[0]);
			}
		},
		selectImageTailImage(event) {
			let _vm = this;
			var input = event.target;
			if (input.files && input.files[0]) {
				_vm.airline.tailImage = input.files[0];
				var reader = new FileReader();
				reader.onload = function (e) {
					$("#imagePreview3").css(
						"background-image",
						"url(" + e.target.result + ")"
					);
					$("#imagePreview3").hide();
					$("#imagePreview3").fadeIn(650);
				};

				reader.readAsDataURL(input.files[0]);
			}
		},
	},
	mounted() {
		if (this.$route.params.id > 0) {
			this.getDetail();
		}
	}
};
